<template>
    <v-container fluid style="margin-bottom:100px">
        <v-row>
            <v-col cols="12">
                 <v-breadcrumbs :items="breadcumbs"></v-breadcrumbs>
                 <template v-slot:item="{ item }">
                 <v-breadcrumbs-item :to="item.href" class="text-subtitle-2 crumb-item" :disabled="item.disabled" exact>
                 {{ item.text }}
                 </v-breadcrumbs-item>
                 </template>
            </v-col>
        </v-row> 
        <v-row class="mt-5">
            <v-col xs="12" sm="4" md="4" class="col-12">
                <v-card class="rounded-l bg_card">
                    <v-card-title>
                        Total Employee
                    </v-card-title>
                    <v-card-text>
                        <div id="chartContainer1" style="height: 300px; width: 100%;"></div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col xs="12" sm="4" md="4" class="col-12">
                <v-card class="rounded-l bg_card">
                    <v-card-title>
                        Employee Type and Stat
                    </v-card-title>
                    <v-card-text>
                        <div id="chartContainer2" style="height: 150px; width: 100%;"></div>
                        <div id="chartContainer3" style="height: 150px; width: 100%;"></div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col xs="12" sm="4" md="4" class="col-12">
                <v-card class="rounded-l bg_card">
                    <v-card-title>
                        Absent by Type (YTD)
                    </v-card-title>
                    <v-card-text>
                        <div id="chartContainer6" style="height: 300px; width: 100%;"></div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col xs="12" sm="6" md="6" class="col-12">
                <v-card class="rounded-l bg_card">
                    <v-card-title>
                        Employee by Region
                    </v-card-title>
                    <v-card-text>
                        <div id="chartContainer4" style="height: 300px; width: 100%;"></div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col xs="12" sm="6" md="6" class="col-12">
                <v-card class="rounded-l bg_card">
                    <v-card-title>
                        Employee by Office
                    </v-card-title>
                    <v-card-text>
                        <div id="chartContainer5" style="height: 300px; width: 100%;"></div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>   
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'KKA',
                disabled: false,
                href: '/admin/kka',
                },
                {
                text: 'HRIS',
                disabled: false,
                href: '#',
                },
                {
                text: 'Overview',
                disabled: true,
                href: '#',
                },
            ],
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{

        initialize(){

            this.getChartData()

        },
        
        renderChart(data1, chartContainer1Title, data2, chartContainer2Title, data3, chartContainer3Title, data4, chartContainer4Title, data5, chartContainer5Title, data6, chartContainer6Title){

            var chart1 = new CanvasJS.Chart("chartContainer1", {
                animationEnabled: true,
                backgroundColor: "transparent",
                title: {
                    text: chartContainer1Title,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                theme: "light2",
                exportEnabled: false,
                legend: {
                    itemclick: this.toggleDataPointVisibility
                },
                data: [{

                    type: "pie",
                    percentFormatString: "#0.##",
                    indexLabel: "{label} #percent%",
                    indexLabelFontSize: 12,
                    showInLegend: true,
                    indexLabelFontColor: "black",
                    legendText: "{label}",
                    dataPoints: data1,

                }]
            })

            var chart2 = new CanvasJS.Chart("chartContainer2", {
                animationEnabled: true,
                backgroundColor: "transparent",
                theme: "light2",
                title: {
                    text: chartContainer2Title,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: false,
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: 'Count Emp by Stat',
                    labelFormatter: this.addSymbols,
                    labelFontColor: "black",
                },
                axisX: {
                    labelFontColor: "black",
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        type: 'bar',
                        color: '#185ADB',
                        indexLabel: "{y}",
                        indexLabelPlacement: "outside",
                        indexLabelFontWeight: "bold",
                        indexLabelFontSize: 12,
                        indexLabelFontColor: "black",
                        indexLabelFontFamily: "calibri",
                        dataPoints: data2,
                    }
                ]
            });

            var chart3 = new CanvasJS.Chart("chartContainer3", {
                animationEnabled: true,
                backgroundColor: "transparent",
                theme: "light2",
                title: {
                    text: chartContainer3Title,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: false,
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: 'Count Emp by Stat',
                    labelFormatter: this.addSymbols,
                    labelFontColor: "black",
                },
                axisX: {
                    labelFontColor: "black",
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        type: 'bar',
                        color: '#01937C',
                        indexLabel: "{y}",
                        indexLabelPlacement: "outside",
                        indexLabelFontWeight: "bold",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        indexLabelFontColor: "black",
                        dataPoints: data3,
                    }
                ]
            });

            var chart4 = new CanvasJS.Chart("chartContainer4", {
                animationEnabled: true,
                exportEnabled: false,
                backgroundColor: "transparent",
                theme: "light2",
                title: {
                    text: chartContainer4Title,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: 'Count Emp by Stat',
                    labelFormatter: this.addSymbols,
                    labelFontColor: "black",
                },
                axisX: {
                    labelFontColor: "black",
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        type: 'column',
                        color: '#293B5F',
                        indexLabel: "{y}",
                        indexLabelPlacement: "outside",
                        indexLabelFontWeight: "bold",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        indexLabelFontColor: "black",
                        dataPoints: data4,
                    }
                ]
            });

            var chart5 = new CanvasJS.Chart("chartContainer5", {
                animationEnabled: true,
                exportEnabled: false,
                backgroundColor: "transparent",
                theme: "light2",
                title: {
                    text: chartContainer5Title,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: 'Count Emp by Stat',
                    labelFormatter: this.addSymbols,
                    labelFontColor: "black",
                },
                axisX: {
                    labelFontColor: "black",
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        type: 'column',
                        color: '#C84B31',
                        indexLabel: "{y}",
                        indexLabelPlacement: "outside",
                        indexLabelFontWeight: "bold",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        indexLabelFontColor: "black",
                        dataPoints: data5,
                    }
                ]
            });

            var chart6 = new CanvasJS.Chart("chartContainer6", {
                animationEnabled: true,
                exportEnabled: false,
                backgroundColor: "transparent",
                theme: "light2",
                title: {
                    text: chartContainer6Title,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: 'Count Absents by Type',
                    labelFormatter: this.addSymbols,
                    labelFontColor: "black",
                },
                axisX: {
                    labelFontColor: "black",
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        type: 'column',
                        color: '#C84B31',
                        indexLabel: "{y}",
                        indexLabelPlacement: "outside",
                        indexLabelFontWeight: "bold",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        indexLabelFontColor: "black",
                        dataPoints: data6,
                    }
                ]
            });

            chart1.render()
            chart2.render()
            chart3.render()
            chart4.render()
            chart5.render()
            chart6.render()

        },

        async getChartData(){

            await axios.get(`${process.env.VUE_APP_URL}/api/kka/hris`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                console.log(res.data);
                this.renderChart(res.data.data1, res.data.total1 + ' Employees', res.data.data2, res.data.total2 + ' Employees(Bulanan)', res.data.data3, res.data.total3 + ' Employees(Harian)', res.data.data4, res.data.total4 + ' Employees By Region',res.data.data5, res.data.total5 + ' Employees By Office',res.data.data6, res.data.total6 + ' Absents'
                )
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },

        toggleDataPointVisibility(e) {
            if(e.dataPoint.hasOwnProperty("actualYValue") && e.dataPoint.actualYValue !== null) {
                e.dataPoint.y = e.dataPoint.actualYValue;
                e.dataPoint.actualYValue = null;
                e.dataPoint.indexLabelFontSize = null;
                e.dataPoint.indexLabelLineThickness = null;
                e.dataPoint.legendMarkerType = "circle";
            } 
            else {
                e.dataPoint.actualYValue = e.dataPoint.y;
                e.dataPoint.y = 0;
                e.dataPoint.indexLabelFontSize = 0;
                e.dataPoint.indexLabelLineThickness = 0; 
                e.dataPoint.legendMarkerType = "cross";
            }
                e.chart.render();
        },

        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        }


    }
    
}
</script>